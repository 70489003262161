import React from "react";

export default () => (
  <div className="relative -container">
    <svg className="w-full" xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 900 255">
      <path
        d="M0 75l15-.7c15-.6 45-2 75-3s60-1.6 90-11.5c30-9.8 60-28.8 90-26.5 30 2.4 60 26 90 33.2s60-2.2 90-12.3C480 44 510 33 540 39.3c30 6.4 60 30 90 34.9 30 4.8 60-9.2 90-15.9 30-6.6 60-6 90-2.8s60 8.8 75 11.7l15 2.8v186H0V75z"
        fill="#dea902"
      />
      <path
        d="M0 110l15-7.8C30 94.3 60 78.7 90 83s60 28.7 90 30.2 60-19.9 90-30.5c30-10.7 60-10.7 90 .5 30 11.1 60 33.5 90 44.1 30 10.7 60 9.7 90 10.9 30 1.1 60 4.5 90-7.9 30-12.3 60-40.3 90-46.1 30-5.9 60 10.5 90 12.6C840 99 870 87 885 81l15-6v181H0V110z"
        fill="#eb6831"
      />
      <path
        d="M0 170l15-7.2c15-7.1 45-21.5 75-26.1 30-4.7 60 .3 90 6.1 30 5.9 60 12.5 90 7.9 30-4.7 60-20.7 90-31.4 30-10.6 60-16 90-13.1 30 2.8 60 13.8 90 16.5 30 2.6 60-3 90 4.1 30 7.2 60 27.2 90 30.9 30 3.6 60-9 90-10.2s60 9.2 75 14.3l15 5.2v89H0v-86z"
        fill="#d3285a"
      />
      <path
        d="M0 142l15 9.3c15 9.4 45 28 75 33.5s60-2.1 90-1.8 60 8.7 90 4.3c30-4.3 60-21.3 90-24.5 30-3.1 60 7.5 90 11.4 30 3.8 60 .8 90-4.4 30-5.1 60-12.5 90-18.3s60-10.2 90-7.5 60 12.3 90 16.5 60 2.8 75 2.2l15-.7v94H0V142z"
        fill="#96137b"
      />
      <path
        d="M0 191l15 3c15 3 45 9 75 9.2 30 .1 60-5.5 90-5s60 7.1 90 8.8 60-1.7 90-2.7 60 .4 90-1.3 60-6.3 90-5 60 8.7 90 7.8c30-.8 60-9.8 90-9.1 30 .6 60 11 90 10s60-13.4 75-19.5l15-6.2v75H0v-65z"
        fill="#272784"
      />
    </svg>
  </div>
);
