import React from "react";
import DecorativeCurve from "../components/DecorativeCurve";
import Layout from "../components/Layout";
import Title from "../components/Title";

const cookiesPolicy = [
  {
    title: "Introduction",
    content: `This cookie policy describes how Coordinator uses “cookies” and other similar technologies,
    in connection with our Site and Services. Any capitalized term used and not otherwise defined below
    has the meaning assigned to it in the Privacy Policy.`,
  },
  {
    title: "What is a cookie?",
    content: `Cookies are small text files that are stored in a computer’s browser directory.
    They help site providers with things like understanding how people use a site, remembering
    a User’s login details, and storing site preferences.`,
  },
  {
    title: "Does Coordinator use Cookies?",
    content: `Yes. We use cookies in accordance with our Privacy Policy to ensure that our Services function properly.`,
  },
  {
    title: "Who sets cookies when I use Coordinator's Site?",
    content: `There are two main types of cookies that can be set. First party cookies:
    these cookies are placed and read by Coordinator directly when you use our Services.
    Coordinator does not set cookies for collecting research and analytics.`,
  },
  {
    title: "How Coordinator uses cookies",
    content: (
      <>
        <p>
          Coordinator stores a session cookie to remember your login state across the site, and an
          anti-CSRF cookie to protect you from malicious agents trying to submit web requests on
          your behalf.
        </p>
        <h3 className="mt-2 text-lg font-semibold">First party cookies</h3>
        <table className="w-full mt-2 mb-4 table-auto bg-neutral-100">
          <thead>
            <tr>
              <th className="p-2 whitespace-nowrap text-neutral-700">Cookie name</th>
              <th className="p-2 whitespace-nowrap text-neutral-700">Purpose</th>
              <th className="p-2 whitespace-nowrap text-neutral-700">Term</th>
            </tr>
          </thead>
          <tbody className="divide-y divide-neutral-200">
            <tr>
              <td className="p-2 font-mono whitespace-nowrap">session</td>
              <td className="p-2">
                This cookie identifies the user's session. It is also used to authenticate logged-in
                users
              </td>
              <td className="p-2 whitespace-nowrap">7 days</td>
            </tr>
            <tr>
              <td className="p-2 font-mono whitespace-nowrap">csrf_token</td>
              <td className="p-2">
                Cross-site request forgery protection. It is used for all forms
              </td>
              <td className="p-2 whitespace-nowrap">Session</td>
            </tr>
          </tbody>
        </table>
      </>
    ),
  },
  {
    title: "Can I opt-out?",
    content: `You can opt out of third party cookies from Stripe, but you cannot opt
    out of first party cookies from Coordinator as those ensure the basic functioning of the website.`,
  },
  {
    title: "Questions or suggestions",
    content: (
      <p>
        If you have any questions, suggestions, or concerns about the issues discussed in this
        document please email us at{" "}
        <a href="mailto:coordinator@tommasoamici.com?subject=Privacy policy on coordinator.tommasoamici.com">
          coordinator@tommasoamici.com
        </a>
        .
      </p>
    ),
  },
];

const CookiesPolicyPage = () => {
  return (
    <Layout seo={{ title: "Cookies policy" }} hasDecoration={true}>
      <article className="flex flex-col items-center justify-center mx-auto my-auto space-y-6 leading-7 max-w-prose">
        <Title>Cookies policy</Title>
        {cookiesPolicy.map((p, i) => (
          <section className="w-full">
            <h2 className="text-2xl font-semibold">
              {i + 1}. {p.title}
            </h2>
            {p.content}
          </section>
        ))}
      </article>
      <DecorativeCurve />
    </Layout>
  );
};

export default CookiesPolicyPage;
